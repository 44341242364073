<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col>
          <template v-for="item in parent ? resolveParents(parent) : item.parent ? resolveParents(item.parent) : []">
            <a
              v-if="item.name"
              :key="item.name"
              :href="
                $router.resolve({
                  name: 'TopicUpdate',
                  params: { id: item['@id'] }
                }).href
              "
              @click="
                (e) => {
                  e.preventDefault();
                  $router.push({
                    name: 'TopicUpdate',
                    params: { id: item['@id'] }
                  });
                }
              "
              >{{ item.name }}/</a
            >
          </template>
          {{ item.name }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="9" sm="9">
          <v-text-field
            outlined
            :error-messages="nameErrors"
            :label="$t('name')"
            @blur="$v.item.name.$touch()"
            @input="$v.item.name.$touch()"
            required
            v-model="item.name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <InputEditor
            v-model="item.cmsTeaser"
            :error-messages="cmsTeaserErrors"
            :label="$t('teaser')"
            :required="false"
            :simple="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <InputEditor
            v-model="item.cmsContent"
            :error-messages="cmsContentErrors"
            :label="$t('description')"
            :required="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-checkbox
            :error-messages="enabledErrors"
            :label="$t('enabled')"
            @blur="$v.item.enabled.$touch()"
            @input="$v.item.enabled.$touch()"
            v-model="item.enabled"
          />
        </v-col>
      </v-row>
      <v-row v-if="this.refForm === 'updateForm'">
        <v-col>
          <v-btn color="primary" :to="{ name: 'TopicCreate', params: { parent: item['@id'] } }">
            {{ $t('CreateChild') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import InputEditor from '@/components/InputEditor';
import TopicMixin from '../../mixins/TopicMixin';

export default {
  name: 'TopicForm',
  mixins: [TopicMixin, validationMixin],
  components: {
    InputEditor
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    },
    initialValues: {
      type: Object,
      default: () => {}
    },
    refForm: {
      type: String,
      required: true
    },
    parent: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      name: null,
      cmsContent: null,
      enabled: false
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.item.name.$dirty) {
        return errors;
      }

      has(this.violations, 'name') && errors.push(this.violations.name);

      !this.$v.item.name.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    cmsTeaserErrors() {
      const errors = [];

      if (!this.$v.item.cmsTeaser.$dirty) {
        return errors;
      }

      has(this.violations, 'cmsTeaser') && errors.push(this.violations.cmsContent);

      return errors;
    },
    cmsContentErrors() {
      const errors = [];

      if (!this.$v.item.cmsContent.$dirty) {
        return errors;
      }

      has(this.violations, 'cmsContent') && errors.push(this.violations.cmsContent);

      !this.$v.item.cmsContent.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    enabledErrors() {
      const errors = [];

      if (!this.$v.item.enabled.$dirty) {
        return errors;
      }

      has(this.violations, 'enabled') && errors.push(this.violations.enabled);
      return errors;
    },
    violations() {
      return this.errors || {};
    }
  },
  methods: {
    resolveParents(parent, path = []) {
      if (typeof parent == 'string') {
        parent = this.resolveTopic(parent);
      }
      path.push(parent.name ? { name: parent.name, '@id': parent['@id'] } : '');

      return parent.parent ? this.resolveParents(parent.parent, path) : path.reverse();
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      cmsTeaser: {},
      cmsContent: {
        required
      },
      enabled: {}
    }
  }
};
</script>
