var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_vm._l((_vm.parent ? _vm.resolveParents(_vm.parent) : _vm.item.parent ? _vm.resolveParents(_vm.item.parent) : []),function(item){return [(item.name)?_c('a',{key:item.name,attrs:{"href":_vm.$router.resolve({
                name: 'TopicUpdate',
                params: { id: item['@id'] }
              }).href},on:{"click":(e) => {
                e.preventDefault();
                _vm.$router.push({
                  name: 'TopicUpdate',
                  params: { id: item['@id'] }
                });
              }}},[_vm._v(_vm._s(item.name)+"/")]):_vm._e()]}),_vm._v(" "+_vm._s(_vm.item.name)+" ")],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9","sm":"9"}},[_c('v-text-field',{attrs:{"outlined":"","error-messages":_vm.nameErrors,"label":_vm.$t('name'),"required":""},on:{"blur":function($event){return _vm.$v.item.name.$touch()},"input":function($event){return _vm.$v.item.name.$touch()}},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('InputEditor',{attrs:{"error-messages":_vm.cmsTeaserErrors,"label":_vm.$t('teaser'),"required":false,"simple":true},model:{value:(_vm.item.cmsTeaser),callback:function ($$v) {_vm.$set(_vm.item, "cmsTeaser", $$v)},expression:"item.cmsTeaser"}})],1)],1),_c('v-row',[_c('v-col',[_c('InputEditor',{attrs:{"error-messages":_vm.cmsContentErrors,"label":_vm.$t('description'),"required":true},model:{value:(_vm.item.cmsContent),callback:function ($$v) {_vm.$set(_vm.item, "cmsContent", $$v)},expression:"item.cmsContent"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-checkbox',{attrs:{"error-messages":_vm.enabledErrors,"label":_vm.$t('enabled')},on:{"blur":function($event){return _vm.$v.item.enabled.$touch()},"input":function($event){return _vm.$v.item.enabled.$touch()}},model:{value:(_vm.item.enabled),callback:function ($$v) {_vm.$set(_vm.item, "enabled", $$v)},expression:"item.enabled"}})],1)],1),(this.refForm === 'updateForm')?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","to":{ name: 'TopicCreate', params: { parent: _vm.item['@id'] } }}},[_vm._v(" "+_vm._s(_vm.$t('CreateChild'))+" ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }